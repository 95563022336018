import { Button } from "../ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Textarea } from "../ui/textarea"
import { BrainCircuit, Code2, GitBranch, Laptop, MessageSquare, Users, Zap, BarChart, Globe } from "lucide-react"
import { Outlet, Link } from "react-router-dom";
import { useState } from "react"
import { HashLink } from 'react-router-hash-link';

export default function Component() {


  return (
    <div className="flex min-h-screen flex-col">
      {/* Hero Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-background to-muted">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl">
                Danilo Freitas,<br/>  
              </h1>
              <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                Empowering startups and scale-ups with over 15 years of tech leadership experience. From ideation to
                scaling, I'll guide your technology strategy to success.
              </p>
            </div>
            <div className="space-x-4">
              <HashLink to="#contact">
                <Button size="lg">Schedule a Consultation</Button>
              </HashLink>
              <HashLink to="#services">
                <Button variant="outline" size="lg">
                  Explore Services
                </Button>
              </HashLink>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Fractional CTO Services</h2>
              <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                Tailored technology leadership to drive your business forward
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8">
            <Card>
              <CardHeader>
                <BrainCircuit className="w-12 h-12 mb-4" />
                <CardTitle>Technology Strategy</CardTitle>
              </CardHeader>
              <CardContent>
                Develop a robust technology roadmap aligned with your business goals and market demands
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <Users className="w-12 h-12 mb-4" />
                <CardTitle>Team Leadership</CardTitle>
              </CardHeader>
              <CardContent>
                Build and mentor high-performing tech teams, fostering a culture of innovation and excellence
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <Code2 className="w-12 h-12 mb-4" />
                <CardTitle>Architecture Design</CardTitle>
              </CardHeader>
              <CardContent>
                Create scalable, efficient system architectures that support your growth and performance needs
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <Zap className="w-12 h-12 mb-4" />
                <CardTitle>Startup Buiding and Scaling</CardTitle>
              </CardHeader>
              <CardContent>
                Guide product/engineering development from ideation to launch, ensuring technical feasibility, market fit, and scalability readyness.
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <BarChart className="w-12 h-12 mb-4" />
                <CardTitle>Data Strategy</CardTitle>
              </CardHeader>
              <CardContent>
                Implement data-driven decision-making processes and analytics solutions to drive business insights
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <Globe className="w-12 h-12 mb-4" />
                <CardTitle>Digital Transformation</CardTitle>
              </CardHeader>
              <CardContent>
                Lead digital transformation initiatives to modernize your technology stack and processes
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-muted">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Areas of Expertise</h2>
              <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                Leveraging deep technical knowledge and industry experience
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8">
            <Card>
              <CardHeader>
                <GitBranch className="w-12 h-12 mb-4" />
                <CardTitle>Software Engineering</CardTitle>
              </CardHeader>
              <CardContent>
                Full-stack development, microservices architecture, and cloud-native solutions
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <Laptop className="w-12 h-12 mb-4" />
                <CardTitle>DevOps & Cloud</CardTitle>
              </CardHeader>
              <CardContent>
                CI/CD pipelines, infrastructure as code, and multi-cloud strategies
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <MessageSquare className="w-12 h-12 mb-4" />
                <CardTitle>Radical Transformation & Recovery</CardTitle>
              </CardHeader>
              <CardContent>
                Leadership evaludation, coaching and replacement; Technology revamp; Team reestructuing; Post M&A rescue.
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Experience Section */}
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Industry Experience</h2>
              <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                Proven track record across diverse sectors
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8">
            <Card>
              <CardHeader>
                <CardTitle>Fintech</CardTitle>
              </CardHeader>
              <CardContent>
                Led technology teams at Nubank, driving innovation in digital banking and financial services
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>E-commerce</CardTitle>
              </CardHeader>
              <CardContent>
                Developed scalable platforms for high-volume transactions and personalized shopping experiences
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>SaaS</CardTitle>
              </CardHeader>
              <CardContent>
                Architected cloud-based solutions for B2B and B2C applications, focusing on scalability and security
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="w-full py-12 md:py-24 lg:py-32 bg-muted">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Let's Connect</h2>
              <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                Ready to elevate your technology strategy? Get in touch or schedule a consultation.
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl gap-6 md:grid-cols-2 mt-8">
            <Card>
              <CardHeader>
                <CardTitle>Coverage</CardTitle>
              </CardHeader>
              <CardContent>
                <span>I work from Germany and I'm available for USA|CA|UK|EU|APAC</span>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Schedule a Consultation</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <p className="text-muted-foreground">
                  Book a 1-on-1 session to discuss your technology challenges and how I can help.
                </p>
                <HashLink to="https://cal.com/danilofreitas" target="_blank">
                  <Button className="w-full" variant="outline">
                    View Available Times
                  </Button>
                </HashLink>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
    </div>
  )
}